import { gql } from '@apollo/client'

export const GET_DATA_QUARTERLY_SUMMARY = gql`
  query GetQuarterlySummary($input: QuarterlySummaryGraphInput!) {
    getQuarterlySummary(input: $input) {
      graphData {
        level
        type
        value
      }
      tableData {
        key
        detail
        summary {
          G
          R
        }
        ORG0000029 {
          G
          R
        }
        ORG0000030 {
          G
          R
        }
      }
    }
  }
`
