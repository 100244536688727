import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { DataTableStyled } from './css/DataTableStyled'
import { CardNew } from '../../../../components/card/Card'
import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { Datatable } from '../../../../components/datatable/Datatable'
import { ResultsOfWorkColumns } from './utils/Constants'
import { useTranslation } from 'react-i18next'

const Responsive = {
  md: 24,
  lg: 24
}

const defaultTable1Data = [
  {
    key: '1',
    detail: 'งานตรวจสอบ',
    ORG0000029: { G: 0, R: 0 },
    ORG0000030: {
      G: 0,
      R: 0
    },
    summary: { G: 0, R: 0 }
  },
  {
    key: '2',
    detail: 'งานให้คำปรึกษา',
    ORG0000029: { G: 0, R: 0 },
    ORG0000030: {
      G: 0,
      R: 0
    },
    summary: { G: 0, R: 0 }
  },
  {
    key: '3',
    detail: 'งานที่ได้รับมอบหมาย',
    ORG0000029: { G: 0, R: 0 },
    ORG0000030: {
      G: 0,
      R: 0
    },
    summary: { G: 0, R: 0 }
  },
  {
    key: '4',
    detail: 'งานสนับสนุน',
    ORG0000029: { G: 0, R: 0 },
    ORG0000030: {
      G: 0,
      R: 0
    },
    summary: { G: 0, R: 0 }
  },
  {
    key: '5',
    detail: 'รวม',
    ORG0000029: { G: 0, R: 0 },
    ORG0000030: {
      G: 0,
      R: 0
    },
    summary: { G: 0, R: 0 }
  }
]

export default function QuarterlySummaryList(props) {
  const { data, loading } = props
  const [resultsOfWork, setResultsOfWork] = useState([])
  const { t } = useTranslation()

  const dynamicResultsOfWorkColumns = ResultsOfWorkColumns([
    {
      title: `${t('ฝ่ายตรวจสอบภายใน')} (IA)`,
      children: [
        { title: t('เป้าหมาย'), dataIndex: ['ORG0000029', 'G'] },
        { title: t('การปฏิบัติ'), dataIndex: ['ORG0000029', 'R'] }
      ]
    },
    {
      title: `${t('ฝ่ายตรวจสอบระบบเทคโนโลยีสารสนเทศ')} (ITA)`,
      children: [
        { title: t('เป้าหมาย'), dataIndex: ['ORG0000030', 'G'] },
        { title: t('การปฏิบัติ'), dataIndex: ['ORG0000030', 'R'] }
      ]
    }
  ])

  useEffect(() => {
    if (Array.isArray(data) && data?.length > 0) {
      setResultsOfWork(data)
    } else {
      setResultsOfWork(defaultTable1Data)
    }
  }, [data])

  return (
    <CardNew topic={t('ตารางผลการดำเนินงาน')} icon="TrendingUp" toggledrop={'false'}>
      <DataTableStyled>
        <Row gutter={[16, 8]}>
          <Col {...Responsive} style={{ marginBottom: '2rem' }}>
            {loading && <SpinnersSmaillNew />}
            <Datatable
              columns={dynamicResultsOfWorkColumns}
              data={resultsOfWork}
              searchCustom={false}
              enableScroll={{ x: false, y: false }}
              showSizeChanger={true}
            />
          </Col>
        </Row>
      </DataTableStyled>
    </CardNew>
  )
}
