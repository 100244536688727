import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'antd'
import { GraphStyled } from './css/GraphStyled'
import { NoData } from '../../../../components/nodatagraph/NoData'
import { IconNew } from '../../../../components/icon/Icon'
import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { GraphColumnGroup } from '../../../../components/graphcolumn/GraphColumnGroup'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { CardNew } from '../../../../components/card/Card'
import { dateDisplay } from '../../../../utilitys/dateHelper'

const Responsive = {
  sm: 24,
  graphFull: {
    md: 24,
    lg: 24
  },
  graphHalf: {
    md: 12,
    lg: 12
  },
  graphThirdCol: {
    md: 8,
    lg: 8
  }
}

export default function QuarterlySummaryGraph(props) {
  const { data, loading, filter } = props
  const { t } = useTranslation()
  const [dataGraph1, setDataGraph1] = useState([])
  const [colorGraph1, setColorGraph1] = useState([])

  useEffect(() => {
    if (Array.isArray(data) && data?.length > 0) {
      setDataGraph1(data.map((item) => ({ ...item, level: t(item.level) })))
      setColorGraph1(colorArrayByType(data))
    }
  }, [data])

  const colorArrayByType = (data) => {
    return data.reduce((acc, cur) => {
      const mappedColor = [
        {
          types: ['R ผลดำเนินงาน'],
          color: '#56CCF2'
        },
        {
          types: ['G เป้าหมาย'],
          color: '#294D83'
        }
      ]

      const findColor = mappedColor.find((item) => item.types.includes(cur.type))
      if (findColor) {
        acc.push(findColor.color)
      } else {
        acc.push('#ccc')
      }

      return acc
    }, [])
  }

  return (
    <CardNew topic={t('ผลการดำเนินงาน')} icon="TrendingUp" toggledrop={'false'}>
      <Row gutter={[16, 8]}>
        <Col {...Responsive.graphFull}>
          <GraphStyled>
            <Card
              className="card-dash card-pie"
              title={
                <Row>
                  <Col md={22} align="left">
                    <span className="card-title-icon">
                      <IconNew icon={'BarChart2'} size={17} />
                    </span>
                    <span className="card-title-text">{`ผลการดำเนินงาน วันที่ ${dateDisplay(
                      filter?.dateStart
                    )} ถึง วันที่ ${dateDisplay(filter?.dateEnd)}`}</span>
                  </Col>
                </Row>
              }
            >
              {loading && <SpinnersSmaillNew />}
              {!_.isEmpty(dataGraph1) ? (
                <GraphColumnGroup data={dataGraph1} colors={colorGraph1} isFullWidth={true} minColumnWidth={60} />
              ) : (
                <NoData />
              )}
            </Card>
          </GraphStyled>
        </Col>
      </Row>
    </CardNew>
  )
}
