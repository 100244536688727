import React, { useState, useMemo } from 'react'
import { BreadcrumbNew } from '../../../../components/breadcrumb/Breadcrumb'
import { breadcrumbData, pageTitle } from './utils/Constants'
import QuarterlySummaryFilter from './components/filter/QuarterlySummaryFilter'
import QuarterlySummaryGraph from './QuarterlySummaryGraph'
import QuarterlySummaryList from './QuarterlySummaryList'
import { useGetQuarterlySummary } from './hooks/useGetQuarterlySummary'

const QuarterlySummaryIndex = () => {
  const [dataFilter, setdataFilter] = useState({
    dateStart: null,
    dateEnd: null,
    organizationCode: null
  })
  const inputOptions = useMemo(() => {
    return { filters: { ...dataFilter } }
  }, [dataFilter])

  const onGetValue = (data) => {
    setdataFilter(data)
  }

  const [resDashboardData, loadingDashboardData] = useGetQuarterlySummary(inputOptions)

  const graphData = useMemo(() => resDashboardData?.graphData, [resDashboardData])
  const table1Data = useMemo(() => resDashboardData?.tableData, [resDashboardData])
  return (
    <React.Fragment>
      <BreadcrumbNew data={breadcrumbData()} title={pageTitle} />
      <QuarterlySummaryFilter getValue={onGetValue} />
      <QuarterlySummaryGraph data={graphData} loading={loadingDashboardData} filter={dataFilter} />
      <QuarterlySummaryList data={table1Data} loading={loadingDashboardData} />
    </React.Fragment>
  )
}

export default QuarterlySummaryIndex
