import React, { useEffect, useState } from 'react'
import { Column } from '@ant-design/plots'
import _ from 'lodash'
import { numberFormat } from '../../utilitys/helper'

export const GraphColumnGroup = (props) => {
  const { data, colors, height, isFullWidth, minColumnWidth } = props
  const [dataGraph, setDataGraph] = useState([])

  const [configs, setConfigs] = useState({
    data: [],
    isStack: true,
    isGroup: true,
    autoFit: isFullWidth,
    xField: 'level',
    yField: 'value',
    seriesField: 'type',
    color: Array.isArray(colors) ? colors : ['#0C6ABF', '#FF3333'],
    maxColumnWidth: minColumnWidth || 50,
    minColumnWidth: minColumnWidth || 20,
    height: height || 300,
    tooltip: {
      formatter: (d) => {
        return { name: d.type, value: numberFormat(d.value) }
      }
    },
    label: {
      position: 'middle',
      layout: [{ type: 'interval-adjust-position' }, { type: 'interval-hide-overlap' }, { type: 'adjust-color' }]
    },
    xAxis: {
      label: {
        style: {
          fontFamily: 'ThSarabunNew',
          fontWeight: 'bold',
          fontSize: 16,
          fill: '#111'
        }
      }
    },
    width: data.length * 28
  })

  useEffect(() => {
    setDataGraph(data)

    if (!_.isEmpty(data)) {
      setConfigs({
        ...configs
      })
    }
  }, [data])

  useEffect(() => {
    setConfigs({
      ...configs,
      data: dataGraph
    })
  }, [dataGraph])

  return !_.isEmpty(data) ? <Column {...configs} /> : null
}
