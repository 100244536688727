import { Trans, useTranslation } from 'react-i18next'
import { numberFormat } from '../../../../../utilitys/helper'

export const pageTitle = 'สรุปผลการดำเนินงานไตรมาส'

export const breadcrumbData = () => {
  const { t } = useTranslation()
  return [{ label: t('แดชบอร์ด'), path: '/dashboard' }, { label: t(pageTitle) }]
}

export const ResultsOfWorkColumns = (dynamicField) => {
  return [
    {
      title: <Trans>{'รายละเอียด'}</Trans>,
      dataIndex: 'detail',
      width: 100,
      align: 'left'
    },
    ...dynamicField.map((field) => ({
      title: field.title,
      children: field.children.map((child) => ({
        title: child.title,
        dataIndex: child.dataIndex,
        width: 50,
        align: 'right',
        render: (val) => numberFormat(val) || '0'
      }))
    })),
    {
      title: <Trans>{'รวม สายงานตรวจสอบ'}</Trans>,
      children: [
        {
          title: <Trans>{'เป้าหมาย'}</Trans>,
          dataIndex: ['summary', 'G'],
          width: 50,
          align: 'right',
          render: (val) => numberFormat(val) || '0'
        },
        {
          title: <Trans>{'การปฏิบัติ'}</Trans>,
          dataIndex: ['summary', 'R'],
          width: 50,
          align: 'right',
          render: (val) => numberFormat(val) || '0'
        }
      ]
    }
  ]
}

// TODO: clear mockup after connect to API
export const resultsOfWorkMockupData = [
  {
    key: '1',
    detail: '1. งานตรวจสอบ',
    ORG0000029: {
      name: 'ฝ่ายตรวจสอบภายใน',
      G: 10,
      R: 8
    },
    ORG0000030: {
      name: 'ฝ่ายตรวจสอบระบบเทคโนโลยีสารสนเทศ',
      G: 12,
      R: 11
    },
    summary: {
      name: 'รวม สายงานตรวจสอบ',
      G: 22,
      R: 19
    }
  },
  {
    key: '2',
    detail: '2. งานให้คำปรึกษา',
    ORG0000029: {
      name: 'ฝ่ายตรวจสอบภายใน',
      G: 5,
      R: 4
    },
    ORG0000030: {
      name: 'ฝ่ายตรวจสอบระบบเทคโนโลยีสารสนเทศ',
      G: 6,
      R: 5
    },
    summary: {
      name: 'รวม สายงานตรวจสอบ',
      G: 11,
      R: 9
    }
  },
  {
    key: '3',
    detail: '3. งานที่ได้รับมอบหมาย',
    ORG0000029: {
      name: 'ฝ่ายตรวจสอบภายใน',
      G: 8,
      R: 7
    },
    ORG0000030: {
      name: 'ฝ่ายตรวจสอบระบบเทคโนโลยีสารสนเทศ',
      G: 9,
      R: 8
    },
    summary: {
      name: 'รวม สายงานตรวจสอบ',
      G: 17,
      R: 15
    }
  },
  {
    key: '4',
    detail: '4. งานสนับสนุน',
    ORG0000029: {
      name: 'ฝ่ายตรวจสอบภายใน',
      G: 7,
      R: 6
    },
    ORG0000030: {
      name: 'ฝ่ายตรวจสอบระบบเทคโนโลยีสารสนเทศ',
      G: 8,
      R: 7
    },
    summary: {
      name: 'รวม สายงานตรวจสอบ',
      G: 15,
      R: 13
    }
  },
  {
    key: '5',
    detail: '5. รวม',
    ORG0000029: {
      name: 'ฝ่ายตรวจสอบภายใน',
      G: 30,
      R: 25
    },
    ORG0000030: {
      name: 'ฝ่ายตรวจสอบระบบเทคโนโลยีสารสนเทศ',
      G: 35,
      R: 31
    },
    summary: {
      name: 'รวม สายงานตรวจสอบ',
      G: 65,
      R: 56
    }
  }
]
